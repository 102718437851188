/**
 * Will act as the default wrapper for all module states within the application
 * that call it within their own Layouts
 */

// import primary libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import Spinner from '../helpers/Spinner.jsx';
import { useLocation } from 'react-router-dom';
// TODO: Maybe find an alternative to react-helmet since it's out of date and causes react to throw warnings
// import { Helmet }  from 'react-helmet'

// import nav components
import AdminNav from '../navigation/AdminNav.jsx';
import StudentNav from '../navigation/StudentNav.jsx';
import InstructorNav from '../navigation/InstructorNav.jsx';
import DefaultNav from '../navigation/DefaultNav.jsx';


const DefaultLayout = ({
  children
  , className = ''
  , title
}) => {

  const location = useLocation();

  // this can replace react-helmet if all we need to do is set the page title.
  useEffect(() => {
    document.title = title ? `${title} | PQ` : "PracticeQuest";
  }, [title])

  return (
    <div className='bg-white min-h-[100vh] min-w-[100vw]'>
      {/* <Helmet title={props.title || "Yote App"}/> */}
      {location.pathname.includes('admin') ?
        <AdminNav />
      : location.pathname.includes('instructor')?
        <InstructorNav/>
      : location.pathname.includes('learn') ?
        <StudentNav/>
      :
        <DefaultNav/>
      }
      <main className={`py-10 px-8 w-full mx-auto ${className}`}>
        {children}
      </main>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node)
    , PropTypes.node
  ]).isRequired
  , className: PropTypes.string
  , title: PropTypes.string
}


DefaultLayout.Skeleton = (props) => {
  return (
    <DefaultLayout title="Loading..." {...props}>
      <Spinner />
    </DefaultLayout>
  )
}

export default DefaultLayout;
