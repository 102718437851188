import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
  , handleAddManyToList
  , handleRemoveManyFromList
} from '../../global/utils/storeUtils';


// First define all API calls for userAssessment

// define and export the strings for the different specific userAssessment endpoints once here because the idea of using strings in the component gives me hives.
// we'll catch for these strings on the server side and apply the correct permissions to the query.
// these are passed in to the userAssessmentService hooks at the component level as the endpoint argument.
// NOTE: If any of these are functions that require arguments, return null until all arguments are provided so the fetching hook will hold off on the fetch instead of calling an endpoint that will fail.
// export const myExampleEndpoint = 'example-endpoint';
// export const myEndpointWithArgs = (arg1, arg2) => {
//   if(!arg1 || !arg2) return null;
//   return `example-endpoint/${arg1}/${arg2}`;
// };

export const userAssessmentsByInstructorAssessmentEndpoint = assessmentId => !assessmentId ? null : `by-instructor-assessment/${assessmentId}`;
export const userAssessmentsByInstructorCourseEndpoint = courseId => !courseId ? null : `by-instructor-course/${courseId}`;
export const userAssessmentsByStudentAssessmentEndpoint = assessmentId => !assessmentId ? null : `by-student-assessment/${assessmentId}`;

/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 *
 * In practice we won't dispatch these directly, they will be dispatched by userAssessmentService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateUserAssessment = createAsyncThunk(
  'userAssessment/sendCreate'
  , async (newUserAssessment) => {
    const endpoint = `/api/user-assessments`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newUserAssessment);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// ACTION to create a UserQuestion as part of this UserAssessment
export const sendGetNextUserQuestion = createAsyncThunk(
  'userAssessment/sendGetNextUserQuestion'
  , async (userAssessmentId) => {
    const endpoint = `/api/user-assessments/${userAssessmentId}/next-question`;
    const response = await apiUtils.callAPI(endpoint, 'POST', {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendSubmitUserQuestionAnswers = createAsyncThunk(
  'userAssessment/sendSubmitUserQuestionAnswers'
  , async ({ _id, ...userQuestion }) => {
    const endpoint = `/api/user-questions/${_id}/submit-answers`;
    const response = await apiUtils.callAPI(endpoint, 'POST', { ...userQuestion });
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


// ACTION to create a UserQuestion as part of this UserAssessment
export const sendPreviewNextQuestion = createAsyncThunk(
  'userAssessment/sendPreviewNextQuestion'
  , async ({ assessmentId, questionId, currentQuestionNumber }) => {
    const endpoint = `/api/user-assessments/${assessmentId}/preview-question/${questionId}/${currentQuestionNumber}`;
    const response = await apiUtils.callAPI(endpoint, 'POST', {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendSubmitPreviewQuestionAnswers = createAsyncThunk(
  'userAssessment/sendSubmitPreviewQuestionAnswers'
    // the data passed here is not a true UserQuestion, it is the required data to allow the instructor to interact with the preview of the question
  , async ({ questionId, ...userQuestionData }) => {
    const endpoint = `/api/user-questions/${questionId}/submit-preview-answers`;
    const response = await apiUtils.callAPI(endpoint, 'POST', { ...userQuestionData });
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleUserAssessment = createAsyncThunk(
  'userAssessment/fetchSingle'
  , async (id) => {
    const endpoint = `/api/user-assessments/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchUserAssessmentList = createAsyncThunk(
  'userAssessment/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/user-assessments${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
// this will hit the same endpoint as the list version, but the store will handle the returned array and access the single item in it.
export const fetchSingleUserAssessmentAtEndpoint = createAsyncThunk(
  'userAssessment/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/user-assessments${query}` // example: `/api/user-assessments/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchUserAssessmentListAtEndpoint = createAsyncThunk(
  'userAssessment/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/user-assessments${query}`; // example: `/api/user-assessments/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateUserAssessment = createAsyncThunk(
  'userAssessment/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/user-assessments/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteUserAssessment = createAsyncThunk(
  'userAssessment/sendDelete'
  , async (id) => {
    const endpoint = `/api/user-assessments/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the userAssessmentSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const userAssessmentSlice = createSlice({
  name: 'userAssessment'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   *
   * Again, we will not dispatch these directly, they will be dispatched by userAssessmentService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addUserAssessmentToList: handleAddSingleToList
    , addUserAssessmentsToList: handleAddManyToList
    , removeUserAssessmentsFromList: handleRemoveManyFromList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   *
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateUserAssessment.fulfilled, handleCreateFulfilled)

      .addCase(sendGetNextUserQuestion.fulfilled, (state, action) => {
        const { userAssessment } = action.payload;
        if(!userAssessment) return;
        const newAction = {
          ...action
          , payload: userAssessment
        }
        handleMutationFulfilled(state, newAction);
      })
      .addCase(sendPreviewNextQuestion.fulfilled, (state, action) => {
        const { userAssessment } = action.payload;
        if(!userAssessment) return;
        const newAction = {
          ...action
          , payload: userAssessment
        }
        handleMutationFulfilled(state, newAction);
      })
      // READ
      .addCase(fetchSingleUserAssessment.pending, handleFetchSinglePending)
      .addCase(fetchSingleUserAssessment.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleUserAssessment.rejected, handleFetchSingleRejected)
      .addCase(fetchUserAssessmentList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchUserAssessmentList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'userAssessments'))
      .addCase(fetchUserAssessmentList.rejected, handleFetchListRejected)

      // permission protected single fetches
      .addCase(fetchSingleUserAssessmentAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleUserAssessmentAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'userAssessments'))
      .addCase(fetchSingleUserAssessmentAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchUserAssessmentListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchUserAssessmentListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'userAssessments'))
      .addCase(fetchUserAssessmentListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateUserAssessment.pending, handleMutationPending)
      .addCase(sendUpdateUserAssessment.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateUserAssessment.rejected, handleMutationRejected)
      // .addCase(sendUpdateUserAssessment.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteUserAssessment.pending, handleDeletePending)
      .addCase(sendDeleteUserAssessment.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteUserAssessment.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addUserAssessmentToList, addUserAssessmentsToList, removeUserAssessmentsFromList } = userAssessmentSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// accepts an optional listFetch action so we can use it for other list fetches, defaults to fetchUserAssessmentList
export const fetchListIfNeeded = (queryKey, listFetch = fetchUserAssessmentList) => (dispatch, getState) => {
  const userAssessmentQuery = getState().userAssessment.listQueries[queryKey];
  if(shouldFetch(userAssessmentQuery)) {
    // console.log('Fetching userAssessment list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

// accepts an optional singleFetch action so we can use it for other single fetches, defaults to fetchSingleUserAssessment
export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleUserAssessment) => (dispatch, getState) => {
  const userAssessmentQuery = getState().userAssessment.singleQueries[id];
  if(shouldFetch(userAssessmentQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default userAssessmentSlice.reducer;
